import { apiClient } from '../API'
import { flexClient } from '../FlexApi'
import qs from 'qs'

/**
 * @typedef {object} EventRun
 * @property {number} id Internal identifier
 * @property {number} eventId Internal event identifier
 * @property {string} eventName Event name
 * @property {string} eventDescription Event description
 * @property {string} initiator Event initiator
 * @property {string} startDate Event start
 * @property {string} endDate Event end
 * @property {number[]} equipmentIds Involved equipment identifiers
 */

/**
 * @typedef {object} EventRunWrite
 * @property {number} id Internal event write identifier
 * @property {number} eventRunDeviceId Internal event device run identifier
 * @property {string} startTime
 * @property {string} endTime
 * @property {number} pointId
 * @property {string} operationId
 * @property {number} operationValue
 * @property {object} operationConfig
 */

/**
 * @typedef {object} EventBuilding
 * @property {string} name Building name
 * @property {string} siteName Site name
 * @property {number} equipmentCount Number of equipment
 * @property {number} id Building identifier
 */

/**
 * @typedef {object} EventProgram
 * @property {string} label Program label
 * @property {string} value Program value
 */

/**
 * @typedef {object} ActionSet
 * @property {number[]} actionIds Array of action identifiers
 */

/**
 * Get event runs for a given site
 * @param {number} siteId
 * @returns {Promise<EventRun[]>}
 */
export function getEventRuns(siteId) {
  let config = {
    params: {
      siteId,
    },
    paramsSerializer: {
      indexes: null,
    },
  }

  return apiClient.get(`/control/event`, config)
}

/**
 * Get monitor points based on equipment and event
 * @param {number} equipmentId
 * @param {number} eventId
 * @returns {Promise<Site[]>}
 */
export function getMonitorPoints(equipmentId, eventId) {
  return apiClient.get(`/control/event/${eventId}/equipment/${equipmentId}/monitor/point`)
}

/**
 * Get event writes.
 * @param {string} runId Internal run identifier.
 * @returns {Promise<EventRunWrite[]>}
 */
export const getEventWrites = (runId) => {
  return apiClient.get(`/control/event/run/${runId}/write`)
}

/**
 * Gets the building IDs.
 * @param {number} programId - The ID of the program.
 * @returns {Promise<{data: {number: string[]}}>}
 */
export const getBuildingIds = (programId) => {
  let config = {
    params: {
      programId: [programId],
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  }
  return flexClient.get(`/building/program-map`, config)
}

/**
 * Get programs.
 * @returns {Promise<EventPrograms[]>}
 */
export const getPrograms = () => {
  return flexClient.get(`/program`)
}

/**
 * Get building information.
 * @param {number} id Building identifier.
 * @returns {Promise<EventBuilding>}
 */
export const getBuildings = (id) => {
  let config = {
    params: {
      id,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  }
  return apiClient.get(`/point/building`, config)
}

/**
 * Schedule Events.
 * @param {number} id Building identifier.
 * @param {Date} start
 * @param {number} duration
 * @returns
 */
export const createEventActionIntervals = (id, start, duration) => {
  const data = {
    intervals: [
      {
        start,
        duration: duration,
        loadReduction: 1,
      },
    ],
  }
  return apiClient.post(`/building/${id}/action/interval`, data)
}
