import UserManagementBaseCard from '@components/UserManagement/UserManagementBaseCard'
import TextField from '@components/Form/TextField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import Button from '@components/Form/Button'
import BuildingSelector from '@components/UserManagement/BuildingSelector'
import { useStoreState } from 'easy-peasy'
import { useState, useEffect, useMemo } from 'react'
import { apiErrorHandler } from '../../Utilities/Error'
import { getSites } from '../../API/Services/Point'
import { getProgramBuildings } from '../../API/Services/Flex'

const FlexAppConfigCard = (props) => {
  // destructure props
  const { pendingForm, setPendingForm, initial, required } = props

  // GLOBAL STATE
  const loggedInUser = useStoreState((state) => state.auth.user)
  const currentPartner = useStoreState((state) => state.partner.selected)

  // LOCAL STATE
  const [loggedInUserSiteOptions, setLoggedInUserSiteOptions] = useState([]) // sites logged-in user has access to
  const [buildingsInFlexProgram, setBuildingsInFlexProgram] = useState([]) // buildings in the flex program

  // get all sites and buildings in the flex program on page load
  useMemo(async () => {
    const programBuildings = await getProgramBuildings()
    setBuildingsInFlexProgram(programBuildings?.data ?? [])
  }, [])

  // get available sites based on logged-in user's access
  useEffect(() => {
    const getSiteOptions = async () => {
      try {
        // get site ids in flex progam
        const sitesInFlexProgram = buildingsInFlexProgram.map((b) => b.siteId)

        // get full site data for flex sites - filtered by logged-in user access
        const userSiteData = await getSites(currentPartner.value, sitesInFlexProgram)

        setLoggedInUserSiteOptions(userSiteData.data) // full site data, not just ids
      } catch (e) {
        apiErrorHandler(e)
      }
    }
    if (loggedInUser) {
      getSiteOptions()
    } else {
      setLoggedInUserSiteOptions([])
    }
  }, [loggedInUser, currentPartner, buildingsInFlexProgram])

  // LOCAL STATE
  const [pendingFormSiteIds, setPendingFormSiteIds] = useState([]) // all site ids in pending form
  const [buildingSelectorCount, setBuildingSelectorCount] = useState(
    pendingFormSiteIds?.length ? pendingFormSiteIds?.length : 1
  ) // how many building selectors should be present
  const [remainingSites, setRemainingSites] = useState(loggedInUserSiteOptions) // how many sites the logged-in user has access to but no building selector is present for

  // handle a change in pending form site ids or logged-in user site options (i.e. partner change)
  useEffect(() => {
    const pendingSiteIds = pendingForm?.access?.siteIds ?? []
    const userAccessiblePendingSiteIds = loggedInUserSiteOptions
      .filter((site) => pendingSiteIds.includes(site.ID))
      .map((s) => s.ID)
    setPendingFormSiteIds(userAccessiblePendingSiteIds) // filter out sites logged-in user doesn't currently have access to
    setRemainingSites(loggedInUserSiteOptions.filter((site) => !userAccessiblePendingSiteIds.includes(site.ID))) // filter out sites already selected
    setBuildingSelectorCount(userAccessiblePendingSiteIds.length ? userAccessiblePendingSiteIds.length : 1) // set building selector count based on pending form site ids
  }, [pendingForm?.access?.siteIds, loggedInUserSiteOptions])

  // add building selector objects for each site in the pending form - minimum of one if no sites in pending form
  // make at least one site config required if flex user role is defined
  const buildingSelectors = []
  for (let i = 0; i < buildingSelectorCount; i++) {
    buildingSelectors.push(
      <div className="row">
        <div className="form-field">
          <BuildingSelector
            required={i === 0 && required}
            key={`building-selector-${i}`}
            pendingForm={pendingForm}
            setPendingForm={setPendingForm}
            pendingFormSiteIds={pendingFormSiteIds}
            siteOptions={remainingSites}
            site={loggedInUserSiteOptions.filter((s) => s.ID === pendingForm?.access?.siteIds[i])}
            buildingsInFlexProgram={buildingsInFlexProgram}
          />
        </div>
      </div>
    )
  }

  // construct card-specific form rows
  // job title only required if flex access is filled out
  const formRows = (
    <div className="form">
      <div className="row" style={{ width: '50%' }}>
        <TextField
          name="role"
          required={required}
          label="Job Title"
          initial={initial && initial?.role}
          value={pendingForm?.role}
          onChange={props.onChange}
        />
      </div>
      <div className="row">
        <div className="form-field">
          <div className="title">
            <span>Building Access</span>
          </div>
        </div>
      </div>
      {buildingSelectors}
      {remainingSites.length > 0 &&
        buildingSelectors.length < loggedInUserSiteOptions.length &&
        pendingFormSiteIds.length === buildingSelectors.length && (
          <div className="row">
            <div className="action">
              <Button
                customClassNames="no-fill"
                label="Add Buildings"
                icon={
                  <FontAwesomeIcon
                    class="icon"
                    icon={faPlusCircle}
                    style={{ color: '#3D2F90', float: 'left', cursor: 'pointer', paddingRight: '8px' }}
                  />
                }
                onClick={() => setBuildingSelectorCount(buildingSelectorCount + 1)}
                style={{ color: '#3D2F90' }}
              />
            </div>
          </div>
        )}
    </div>
  )

  return <UserManagementBaseCard icon={faBuilding} groupName={'Flex App'} formRows={formRows} />
}

export default FlexAppConfigCard
