import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useMemo, useCallback, useState, useEffect } from 'react'
import Table from '../../Components/Table'
import CreateButton from '../../Components/Service/CreateButton'
import { Portal } from 'react-portal'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const Users = () => {
  const [pagination, setPagination] = useState({})

  const partner = useStoreState((state) => state.partner.selected)
  const users = useStoreState((state) => state.users.users)
  const userPageCount = useStoreState((state) => state.users.userPageCount)
  const usersLoading = useStoreState((state) => state.users.usersLoading)
  const setUserByUsername = useStoreActions((actions) => actions.users.setUserByUsername)
  const getUsers = useStoreActions((actions) => actions.users.getUsers)

  const setUserFilters = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
    const filter = filters?.reduce((acc, value) => {
      if (value.id == 'username') {
        // The column named 'username' is actually filtering on a user's email, this needs to be correctly mapped.
        acc['email'] = value.value
      } else if (value.id == 'Name') {
        // The column named 'Name' is actually filtering on a user's name property.
        acc['name'] = value.value
      } else {
        acc[value.id] = value.value
      }

      return acc
    }, {})

    setPagination({ pageSize, pageIndex, filter, sortBy })
  }, [])

  useEffect(() => {
    getUsers(pagination)
  }, [pagination, partner])

  // -- TABLE SETTINGS --
  // all valid column options for certificates
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row) => {
          return `${row.firstName ?? ''} ${row.lastName ?? ''}`
        },
      },
      {
        Header: 'Email',
        accessor: 'username',
      },
      {
        Header: 'Phone Number',
        accessor: 'phone',
      },
      {
        Header: 'Flex Access',
        accessor: 'flexBuildingCount',
        disableFilters: true,
        Cell: ({ value }) => {
          const hasValue = value !== undefined
          const message = hasValue ? `${value} building${value == 1 ? '' : 's'}` : 'no access'

          return <div style={{ fontStyle: hasValue ? 'normal' : 'italic' }}>{message}</div>
        },
      },
      {
        Header: 'Torrens Access',
        accessor: 'torrensRole',
        disableFilters: true,
        Cell: ({ value }) => {
          const hasValue = value !== undefined
          const message = hasValue ? value : 'no access'

          return <div style={{ fontStyle: hasValue ? 'normal' : 'italic' }}>{message}</div>
        },
      },
      {
        Header: '',
        id: 'user_edit',
        accessor: 'username',
        disableFilters: true,
        Cell: ({ value }) => (
          <Link
            className="edit-button"
            onClick={() => {
              setUserByUsername(value)
            }}
            to="edit"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Link>
        ),
      },
    ],
    []
  )

  return (
    <div className="App-submodule">
      <div className="table">
        <Portal node={document && document.getElementById('buttons-portal-target')}>
          <div className="createNewButton" style={{ marginLeft: 'auto' }}>
            <CreateButton refreshUsers={() => getUsers()} selectedTab={'users'} />
          </div>
        </Portal>
        <Table
          loading={usersLoading}
          singleSelect
          columns={tableColumns}
          data={users ?? []}
          fetchData={setUserFilters}
          pageCount={userPageCount}
          initialPageSize={15}
        />
      </div>
    </div>
  )
}

export default Users
