import DropdownField from '../Form/DropdownField'
import './BuildingSelector.scss'
import { useState, useMemo, useEffect } from 'react'
import { apiErrorHandler } from '../../Utilities/Error'
import { getBuildings } from '../../API/Services/Point'
import SearchBox from '@components/SearchBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Button from '@components/Form/Button'

const BuildingSelector = (props) => {
  // destructure props
  const { pendingForm, pendingFormSiteIds, siteOptions, site, buildingsInFlexProgram } = props

  // LOCAL STATE
  const [searchBoxValue, setSearchBoxValue] = useState(undefined)
  const [buildings, setBuildings] = useState([]) // available buildings from this site site
  const [filteredRows, setFilteredRows] = useState([]) // filtered buildings from this site
  const [selected, setSelected] = useState([]) // selected buildings from this site
  const [pendingFormBuildingIds, setPendingFormBuildingIds] = useState([]) // all building ids in pending form
  const [showRemoveSite, setShowRemoveSite] = useState(false) // show remove site button

  // format site options for DropdownField
  const siteOptions_formatted = useMemo(() => {
    return siteOptions ? siteOptions.map((x) => ({ value: x.ID, label: x.Name })) : []
  }, [siteOptions])

  // store pending form's building ids for use below
  useMemo(() => {
    setPendingFormBuildingIds(pendingForm?.access?.buildingIds ?? [])
  }, [pendingForm])

  /**
   * Handler for site dropdown select updates on the pending object
   * @param {Object} option - Selected option from the dropdown field
   * @param {string} name - Name of the field getting updated by the dropdown
   */
  const handleSiteSelectChange = (option, name) => {
    const value = option?.value ? [option?.value] : []

    // Remove siteid provided to this component from pending form's site ids list, then concat value to that list
    const updatedSiteIds = pendingFormSiteIds.length
      ? pendingFormSiteIds.filter((id) => id !== site[0]?.ID).concat(value)
      : value

    // set pending form with updated site ids
    props.setPendingForm({
      ...pendingForm,
      access: {
        siteIds: updatedSiteIds,
        buildingIds: pendingForm?.access?.buildingIds,
      },
    })
  }

  // update pending form with selected buildings
  const handlePendingFormUpdate = (buildingIds) => {
    props.setPendingForm({
      ...pendingForm,
      access: {
        siteIds: pendingForm?.access?.siteIds,
        buildingIds: buildingIds,
      },
    })
  }

  // remove all pending form buildings associated with this site when clearing local selection
  const resetBuildingSelection = () => {
    handlePendingFormUpdate(pendingFormBuildingIds.filter((id) => !selected.includes(id)))
    setSelected([])
  }

  // get available buildings when a site is selected and reset building selector when site is cleared
  useEffect(() => {
    const fetchData = async () => {
      try {
        // get buildings for the selected site
        const buildingResults = await getBuildings(site[0].ID)
        const programBuildingIds = buildingsInFlexProgram.filter((b) => b.siteId === site[0].ID).map((bldg) => bldg.id)
        const filteredBuildingsResults = buildingResults.data.filter((b) => programBuildingIds.includes(b.id))
        setBuildings(filteredBuildingsResults)
        // set selected to any matching buildings from pending form
        setSelected(pendingFormBuildingIds.filter((id) => filteredBuildingsResults.map((b) => b.id).includes(id)))
      } catch (e) {
        apiErrorHandler(e)
      }
    }
    if (site[0]?.ID) {
      // clear buildings if site id changes
      if (pendingFormBuildingIds?.length && buildings?.length) resetBuildingSelection()

      // get buildings for the selected site
      fetchData()

      // show delete button
      setShowRemoveSite(true)
    } else {
      // clear buildings if no site is selected
      setBuildings([])

      // reset local building selections
      resetBuildingSelection()

      // hide delete button
      setShowRemoveSite(false)
    }

    // clear search box value either way
    setSearchBoxValue(undefined)
  }, [site[0]?.ID])

  // filter buildings based on search box value
  useEffect(() => {
    if (searchBoxValue) {
      setFilteredRows(
        buildings.filter((b) => {
          return b?.name?.toLowerCase().includes(searchBoxValue.toLowerCase())
        })
      )
    } else {
      setFilteredRows(buildings)
    }
  }, [buildings, searchBoxValue])

  // Handle adding and removing building ids from local selection
  const handleSelected = (id) => {
    if (selected.includes(id)) {
      // remove selection
      setSelected(selected.filter((x) => x !== id))
      handlePendingFormUpdate(pendingFormBuildingIds.filter((b) => b !== id))
    } else {
      // add selection
      setSelected([...selected, id])
      handlePendingFormUpdate(pendingFormBuildingIds.concat(id))
    }
  }

  // Handle checkbox interaction
  const handleCheckbox = (b) => {
    const id = parseInt(b.target.id)
    handleSelected(id)
  }

  // Handle select all interaction
  const handleSelectAll = () => {
    // if selected equals filteredRows, and both are defined, clear selected
    if (
      selected.length &&
      filteredRows.length === selected.length &&
      filteredRows.every((b) => selected.includes(b.id))
    ) {
      resetBuildingSelection()
    } else {
      // add all filtered rows to selected and pending form
      setSelected(filteredRows.map((b) => b.id))
      handlePendingFormUpdate([...new Set(pendingFormBuildingIds.concat(filteredRows.map((b) => b.id)))])
    }
  }

  // show selector if site options are available or if only available site is already selected
  return siteOptions_formatted.length > 0 || site.length > 0 ? (
    <div className="building-selector">
      <div className="site-header">
        <Button
          customClassNames="no-fill"
          hidden={!showRemoveSite}
          icon={<FontAwesomeIcon class="icon" icon={faTrashAlt} />}
          onClick={() => handleSiteSelectChange()} // remove this site and associated buildings from pending form
          style={{ padding: '0', color: '#3D2F90' }}
        />
        <div className="dropdown-selector">
          <DropdownField
            required={props.required ?? false}
            placeholder="Select"
            label="Site"
            name="siteId"
            options={siteOptions_formatted}
            onChange={handleSiteSelectChange}
            value={site?.map((s) => ({ value: s.ID, label: s.Name }))}
          />
        </div>
      </div>
      {site.length > 0 && (
        <div className="building-header">
          <div className="selection-info">
            <div className="title">
              <span>Buildings</span>
            </div>
            {filteredRows.length > 0 ? (
              <div className="options">
                <div className="selected">
                  <span>{selected.length} selected</span>
                </div>
                <div className="spacer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="20" viewBox="0 0 2 20" fill="none">
                    <path d="M1 0V20" stroke="#CAD1D6" />
                  </svg>
                </div>
                <div className="select-all" onClick={() => handleSelectAll()}>
                  Select All {filteredRows.length}
                </div>
              </div>
            ) : (
              <span>No buildings available.</span>
            )}
          </div>
          {buildings.length > 0 && (
            <SearchBox onUpdate={(e) => setSearchBoxValue(e.target.value)} value={searchBoxValue} />
          )}
        </div>
      )}
      {buildings.length > 0 && (
        <div className="building-table-container">
          <table>
            <tbody>
              {filteredRows &&
                filteredRows.map((b) => (
                  <tr key={b.id}>
                    <td>
                      <input
                        className="row-checkbox"
                        id={b.id}
                        checked={selected.includes(b.id)}
                        type="checkbox"
                        name="buildingId"
                        onClick={(e) => handleCheckbox(e)}
                      />
                    </td>
                    <td>
                      <div className="building-row">
                        <span className="name">{`${b.name}`}</span>
                        <span className="address">{`${b.address}, ${b.city}, ${b.state}, ${b.zip}`}</span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  ) : (
    'Unable to modify building access.'
  )
}

export default BuildingSelector
