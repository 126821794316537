import React, { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Stepper from '../../Components/Stepper'
import EventSchedulerSettings from './EventSchedulerSettings'
import EventSchedulerReview from './EventSchedulerReview'
import { createEventActionIntervals } from '../../API/Services/Control'
import { apiErrorHandler } from '../../Utilities/Error'
import Helpers from '@utilities/Helpers'
import Loader from '../../Components/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment-timezone'

const EventScheduler = () => {
  const navigate = useNavigate()
  const steps = ['Settings', 'Review']
  const [loader, setLoader] = useState(false)
  const [isProcessCompleted, setIsProcessCompleted] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [isSettingsValid, setIsSettingsValid] = useState(false)
  const [schedulerData, setSchedulerData] = useState(null)

  // handle changes in settings validity
  const handleSettingsValidityChange = useCallback((isValid, data) => {
    setIsSettingsValid(isValid)
    if (isValid) {
      setSchedulerData(data)
    }
  }, [])

  // Determine if the current step is valid
  const isCurrentStepValid = currentStep === 0 ? isSettingsValid : true

  // Handle step change
  const handleStepChange = (newStep) => {
    setCurrentStep(newStep)
  }

  // Handle cancellation
  const handleCancel = () => {
    navigate('/event')
  }

  const handleSubmit = async () => {
    const dateTimeString = `${schedulerData.selectedDate}T${Helpers.parseTime(schedulerData.startTime)}:00`

    let selectedDateTime

    // if tz exists then use it in the selected date
    if (schedulerData.selectedTimezone) {
      selectedDateTime = moment.tz(dateTimeString, schedulerData.selectedTimezone)
    } else {
      // if not use it as is
      selectedDateTime = moment(dateTimeString)
    }
    setLoader(true)
    // Handle promises
    const promises = schedulerData.selectedRows.map((item) =>
      createEventActionIntervals(item.id, selectedDateTime.toDate(), parseInt(schedulerData.duration) * 60)
    )

    try {
      // Wait for all scheduling promises to resolve
      await Promise.all(promises)

      setIsProcessCompleted(true)
    } catch (error) {
      // If error show toast
      apiErrorHandler({ response: { data: 'Event Scheduling Failed. Please review event details and try again.' } })
    }
    setLoader(false)
  }

  const EventSchedulerTitle = () => {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <div className={`step-indicator completed`}>
            <FontAwesomeIcon width={15} height={15} icon={faCheck} />
          </div>
          <span style={{ marginLeft: '20px' }} className="step-title">
            Event Scheduled Successfully!
          </span>
        </div>
      </>
    )
  }

  const renderStepContent = () => {
    return (
      <>
        {loader && <Loader isRelative={false} text={'Scheduling Event...'} />}
        {isProcessCompleted && <EventSchedulerTitle />}
        <div style={{ display: currentStep === 0 ? 'block' : 'none' }} className="settings-form-wrapper">
          <EventSchedulerSettings onValidityChange={handleSettingsValidityChange} />
        </div>
        <div style={{ display: currentStep === 1 ? 'block' : 'none' }}>
          {schedulerData && (
            <>
              <EventSchedulerReview schedulerData={schedulerData} />
            </>
          )}
        </div>
      </>
    )
  }
  return (
    <>
      <Stepper
        title={'Schedule Event'}
        steps={steps}
        currentStep={currentStep}
        isStepValid={isCurrentStepValid}
        onStepChange={handleStepChange}
        handleCancel={handleCancel}
        onSave={handleSubmit}
        loading={loader}
        isCompleted={isProcessCompleted}
      />
      {renderStepContent()}
    </>
  )
}

export default EventScheduler
